<template>
  <div v-if="loading" class="loading-root" :class="{ '-loaded': loaded }">
    <!-- ローディング -->
    <div id="loading_box">
      <div class="loading_box_border">
        <div class="circle_core"></div>
      </div>
      <div class="loading_mark">
        <object
          type="image/svg+xml"
          data="/loading/jinlo2.svg"
          width="150"
          height="150"
        ></object>
      </div>
      <div class="loadtxt">
        <p>LOADING</p>
      </div>
    </div>
  </div>

  <!-- ローディング中 -->
  <div v-if="firstLoading" id="first_loading_box">
    <div class="first_loading_box_border">
      <div class="first_circle_core"></div>
    </div>
    <div class="first_border_loading_indicator"></div>
    <div class="first_load_txt">LOADING</div>
  </div>

  <!-- ロード完了 -->
  <div v-if="firstLoaded" id="start_box_before">
    <object
      type="image/svg+xml"
      data="/loading/jinlo.svg"
      width="260"
      height="260"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, useNuxtApp } from '#imports'

const nuxtApp = useNuxtApp()
const loading = ref(false)
const loaded = ref(false)
const firstLoading = ref(true)
const firstLoaded = ref(false)
const LOAD_COUNT_NUM = 2
const loadCounter = ref(0)

nuxtApp.hook('page:start', () => {
  /** 初回ローディング */
  if (firstLoading.value) return

  /** 2回目以降ローディング */
  loaded.value = false
  loading.value = true
})

const finishFunction = () => {
  /** 初回ローディング */
  loadCounter.value++
  if (loadCounter.value < LOAD_COUNT_NUM) return
  if (firstLoading.value) {
    firstLoading.value = false
    firstLoaded.value = true
    setTimeout(() => {
      firstLoaded.value = false
    }, 3000)
    return
  }

  /** 2回目以降ローディング */
  setTimeout(() => {
    loaded.value = true
    setTimeout(() => {
      loading.value = false
    }, 300)
  }, 500)
}

fetch('/loading/jinlo.svg').then(finishFunction)
nuxtApp.hook('page:finish', finishFunction)
</script>
<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;

/** ローディング*/
@-webkit-keyframes rolling {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rolling {
  0% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes rolling {
  0% {
    -o-transform: rotate(0);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rolling {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadein-keyframes {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#loading_box {
  width: 200px;
  height: 44px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%
  );
  position: fixed;
  right: 0;
  bottom: 40px;
  z-index: 1000;
  color: #a458ac;
  font-size: 16px;
  border-bottom: 1px solid #a458ac;
}

.loading_box_border {
  background: none;
  right: 102px;
  width: 36px;
  height: 36px;
  border: 2px solid transparent;
  border-radius: 100%;
  border-color: #a458ac transparent transparent;
  position: absolute;
  overflow: hidden;
  animation: spin 0.6s ease 0s infinite normal;
}

.circle_core {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 50%;
}

.loading_mark {
  position: relative;
  top: 3px;
  left: 15px;
}

.loadtxt {
  position: relative;
  right: 30px;
  top: 2px;
}

/** 初回ローディング*/
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes loading_indicator {
  0% {
    border-color: rgba(255, 255, 255, 1);
    border-top-color: #f2e8f5;
  }
  25% {
    border-color: rgba(255, 255, 255, 1);
    border-right-color: #f2e8f5;
  }
  50% {
    border-color: rgba(255, 255, 255, 1);
    border-bottom-color: #f2e8f5;
  }
  75% {
    border-color: rgba(255, 255, 255, 1);
    border-left-color: #f2e8f5;
  }
  100% {
    border-color: rgba(255, 255, 255, 1);
    border-top-color: #f2e8f5;
  }
}

@keyframes fadein-keyframes {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  min-height: 90vh;
  background-color: #eeeeee;
  display: flex;
  flex-wrap: wrap;
  color: #a458ac;
}

.loading-root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: auto;
  width: 100%;
  top: 50%;
  bottom: 50%;
  color: #a458ac;
  opacity: 1;
  transition: 0.3s;
  z-index: v.$zindex-loading;
  pointer-events: none;
  &.-loaded {
    opacity: 0;
    visibility: hidden;
  }
}
.first_load_txt {
  font-size: 12px;
  position: absolute;
  color: #a458ac;
}

#first_loading_box {
  margin: auto;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
}
.first_loading_box_border {
  width: 64px;
  height: 64px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(152, 110, 208);
  background: linear-gradient(
    0deg,
    rgba(152, 110, 208, 1) 66%,
    rgba(248, 84, 123, 1) 95%,
    rgba(255, 255, 255, 1) 100%
  );
  animation: rotate 1s linear 0s infinite;
}

.first_circle_core {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 50%;
}
.first_border_loading_indicator {
  width: 0px;
  height: 0px;
  position: absolute;
  margin: auto;
  border: 0px solid #f2e8f5;
  border-width: 28px;
  animation: loading_indicator 1s linear infinite;
  border-radius: 100%;
}

/** ロード完了リアクション */
#start_box_before {
  margin: auto;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  animation: fadein-keyframes 0.5s ease 2s 1 forwards;
}
</style>
