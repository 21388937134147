<template>
  <div id="header">
    <header
      ref="headerRef"
      class="header-container"
      :class="{ 'header-small': isHeaderSmall }"
    >
      <nav id="nav" class="container-nav" :class="{ '-active': isMenu }">
        <nuxt-link class="logo-link" to="/">
          <AtomsImgEl
            class="logo"
            src="/logo.svg"
            alt="仁Studio株式会社"
            @click="menuClose"
          />
        </nuxt-link>
        <ul class="nav">
          <li
            id="information"
            class="nav-item accordion-item"
            :class="{ '-active': isInformationActive }"
          >
            <div class="inner-nav title">INFORMATION</div>
            <nuxt-link to="/#news" class="accordion-content nav-item2">
              <div class="accordion-content-link inner-nav2">NEWS</div>
            </nuxt-link>
            <nuxt-link to="/#contents" class="accordion-content nav-item2">
              <div class="accordion-content-link inner-nav2">CONTENTS</div>
            </nuxt-link>
          </li>

          <li
            id="about-us"
            class="nav-item accordion-item"
            :class="{ '-active': isAboutUsActive }"
          >
            <div class="inner-nav title">ABOUT US</div>
            <nuxt-link to="/#about" class="accordion-content nav-item2">
              <div class="accordion-content-link inner-nav2">ABOUT</div>
            </nuxt-link>
            <nuxt-link to="/#service" class="accordion-content nav-item2">
              <div class="accordion-content-link inner-nav2">SERVICE</div>
            </nuxt-link>
            <nuxt-link to="/#staff" class="accordion-content nav-item2">
              <div class="accordion-content-link inner-nav2">STAFF</div>
            </nuxt-link>
            <nuxt-link to="/office" class="accordion-content nav-item2">
              <div class="accordion-content-link inner-nav2">OFFICE</div>
            </nuxt-link>
          </li>

          <li class="nav-item" :class="{ '-active': isWorksActive }">
            <nuxt-link to="/#works">
              <div class="inner-nav title">WORKS</div>
            </nuxt-link>
          </li>
          <li class="nav-item" :class="{ '-active': isCompanyActive }">
            <nuxt-link to="/company">
              <div class="inner-nav title">COMPANY</div>
            </nuxt-link>
          </li>
          <li class="nav-item" :class="{ '-active': isRecruitActive }">
            <nuxt-link to="/recruit">
              <div class="inner-nav title">RECRUIT</div>
            </nuxt-link>
          </li>
          <li class="nav-item" :class="{ '-active': isContactActive }">
            <nuxt-link to="/contact">
              <div class="inner-nav title">CONTACT</div>
            </nuxt-link>
          </li>
        </ul>
      </nav>
      <div
        id="nav-mobile-header"
        class="nav-mobile"
        :class="{ '-active': isMenu }"
      >
        <div @click="menuClick"><span></span><span></span><span></span></div>
      </div>
      <div
        id="nav-mobile-bg"
        class="nav-mobile-bg"
        :class="{ '-active': isMenu }"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <nuxt-link to="/#news" class="nav-link" @click="menuClick">
              <span>NEWS</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/#contents" class="nav-link" @click="menuClick">
              <span>CONTENTS</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/#about" class="nav-link" @click="menuClick">
              <span>ABOUT</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/#service" class="nav-link" @click="menuClick">
              <span>SERVICE</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/#staff" class="nav-link" @click="menuClick">
              <span>STAFF</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/office" class="nav-link" @click="menuClick">
              <span>OFFICE</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/#works" class="nav-link" @click="menuClick">
              <span>WORKS</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/company" class="nav-link" @click="menuClick">
              <span>COMPANY</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/recruit" class="nav-link" @click="menuClick">
              <span>RECRUIT</span>
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link to="/contact" class="nav-link" @click="menuClick">
              <span>CONTACT</span>
            </nuxt-link>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>
<script lang="ts">
import { RouteLocationNormalizedLoaded } from 'vue-router'
import { defineComponent, onMounted, ref, useRoute, watch } from '#imports'

export default defineComponent({
  setup() {
    const route = useRoute()
    const isMenu = ref<boolean>(false)
    const isScroll = ref<boolean>(false)
    const headerRef = ref<HTMLElement | null>(null)
    const isHeaderSmall = ref<boolean>(false)
    const isTopActive = ref<boolean>(false)
    const isAboutActive = ref<boolean>(false)
    const isServiceActive = ref<boolean>(false)
    const isWorksActive = ref<boolean>(false)
    const isStaffActive = ref<boolean>(false)
    const isCompanyActive = ref<boolean>(false)
    const isOfficeActive = ref<boolean>(false)
    const isRecruitActive = ref<boolean>(false)
    const isContactActive = ref<boolean>(false)

    const isNewsActive = ref<boolean>(false)
    const isInformationActive = ref<boolean>(false)
    const isAboutUsActive = ref<boolean>(false)
    const isBusinessActive = ref<boolean>(false)
    const isContentsActive = ref<boolean>(false)
    const checkHeaderSmall = (route: RouteLocationNormalizedLoaded) => {
      const beforeIsScroll = isScroll.value
      const path = route.path
      isScroll.value = path === '/' || path.startsWith('/#')
      // 常時表示
      // isHeaderSmall.value = !isScroll.value
      isHeaderSmall.value = true
      isRecruitActive.value = path === '/recruit'
      isCompanyActive.value = path === '/company'
      isOfficeActive.value = path === '/office'
      isContactActive.value = path === '/contact'
      isWorksActive.value = path === '/works'
      if (isScroll.value === beforeIsScroll) {
        return
      }

      const hash = route.hash
      if (!hash) {
        if (path === '/') {
          targetElementActive()
        } else {
          isTopActive.value = false
          isAboutActive.value = false
          isServiceActive.value = false
          isStaffActive.value = false

          isNewsActive.value = false
          isInformationActive.value = false
          isAboutUsActive.value = false
          isBusinessActive.value = false
          isContentsActive.value = false
        }
        return
      }
      targetElementActive(hash)
    }

    onMounted(() => {
      watch(route, checkHeaderSmall)
      checkHeaderSmall(route)
      window.addEventListener('scroll', scrollFunc)
      scrollFunc()
    })

    const scrollFunc = () => {
      if (!isScroll.value) {
        return
      }

      isHeaderSmall.value = true

      // 以下はページ内遷移の場合の設定
      // ページ遷移は常時表示の設定

      // 画面内の最後尾を設定
      if (checkElementActive('#contents')) {
        targetElementActive('#information')
        return
      }

      // 画面内の最後尾からの並び順は先頭で設定
      if (checkElementActive('#staff')) {
        targetElementActive('#about-us')
        return
      }

      if (checkElementActive('#works')) {
        targetElementActive('#works')
        return
      }

      if (checkElementActive('#service')) {
        targetElementActive('#about-us')
        return
      }

      if (checkElementActive('#about')) {
        targetElementActive('#about-us')
        return
      }

      // 画面内の先頭を設定
      if (checkElementActive('#news')) {
        targetElementActive('#information')
        return
      }

      targetElementActive()
    }

    const checkElementActive = (hash: string) => {
      const targetElement = document.querySelector(hash)
      if (!targetElement) {
        return false
      }
      return (
        targetElement.getBoundingClientRect().top - window.innerHeight / 2 < 0
      )
    }

    const targetElementActive = (hash: string = '') => {
      isTopActive.value = hash === ''
      isAboutActive.value = hash === '#about'
      isServiceActive.value = hash === '#service'
      isWorksActive.value = hash === '#works'
      isStaffActive.value = hash === '#staff'

      isNewsActive.value = hash === '#news'
      isInformationActive.value = hash === '#information'
      isAboutUsActive.value = hash === '#about-us'
      isBusinessActive.value = hash === '#business'
      isContentsActive.value = hash === '#contents'
    }

    const menuClick = () => {
      isMenu.value = !isMenu.value
    }

    const menuClose = () => {
      isMenu.value = false
    }

    return {
      isMenu,
      headerRef,
      isHeaderSmall,
      isTopActive,
      isAboutActive,
      isServiceActive,
      isWorksActive,
      isStaffActive,
      isCompanyActive,
      isOfficeActive,
      isRecruitActive,
      isContactActive,
      isNewsActive,
      isInformationActive,
      isAboutUsActive,
      isBusinessActive,
      isContentsActive,
      menuClick,
      menuClose,
    }
  },
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/mixins' as m;
@use '@/assets/styles/variables' as v;

#header {
  position: fixed;
  width: 100%;
  z-index: v.$zindex-header;
  font-size: 18px;
}

.header-container {
  background: rgba(255, 255, 255, 0);
  transition: 0.2s ease-out;
}

.header-small {
  background: rgba(255, 255, 255, 1);
  padding-bottom: 12px;
  border-bottom-right-radius: 60% 16px;
  border-bottom-left-radius: 60% 16px;

  > .container-nav .nav-item .title {
    margin: 0;
    color: v.$gray-1;
  }
}

.logo-link {
  width: 338px;

  @include m.sp() {
    width: 230px;
  }
}

.logo {
  height: 116px;
  margin-left: 46px;
  margin-top: 16px;
  width: auto;
  transition: 0.5s ease-out;

  @include m.sp() {
    height: 60px;
    margin-left: 0;
    margin-top: 12px;
  }
}

.header-small {
  .logo {
    transform: scale(0.7) translate(-60px, -40px);

    @include m.sp() {
      transform: scale(0.9) translateY(-12px);
    }
  }
}

.container-nav {
  height: 100px;
  width: 100%;
  max-width: 1720px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  transition: 0.5s ease-out;

  @include m.sp() {
    height: 66px;
  }
}

.header-small {
  .container-nav {
    height: 68px;

    @include m.sp() {
      //height: 48px;
      height: 44px;
    }
  }
}

.nav {
  display: flex;
  margin-top: 50px;
  margin-right: 128px;
  font-size: 16px;
  transition: 0.5s ease-out;

  > .nav-item {
    display: block;
    width: 110px;
    height: 44px;
    text-align: center;
    position: relative;
    cursor: pointer;
    margin-left: 10px;
  }
}

.nav-item2 {
  display: block;
  width: 110px;
  height: 44px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.header-small {
  .nav {
    margin-top: 24px;
  }
}

.nav-item2 .title,
.nav-item .title {
  color: v.$white;
  margin-bottom: 0;
  transition: 0.2s ease-out;
}

/*線の基点位置*/
.nav-item2::before,
.nav-item2::after,
.nav-item::before,
.nav-item::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  opacity: 1;
  transition: all 0.15s ease-in;
}

.nav-item2::before,
.nav-item::before {
  background: linear-gradient(
    60deg,
    rgb(173, 109, 191) 0%,
    rgb(248, 84, 123) 100%
  );
  right: 0;
  top: 0;
}

.nav-item2::after,
.nav-item::after {
  background: linear-gradient(
    60deg,
    rgb(152, 110, 208) 0%,
    rgb(209, 102, 161) 100%
  );
  left: 0;
  bottom: 0;
}

/*線の基点位置2 .inner-navタグ*/
.nav-item2 .inner-nav2,
.nav-item .inner-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
}

.nav-item2 .inner-nav2::before,
.nav-item2 .inner-nav2::after,
.nav-item .inner-nav::before,
.nav-item .inner-nav::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 0;
  transition: all 0.1s ease-in;
  transition-delay: 0.15s;
  opacity: 1;
}

.nav-item2 .inner-nav2::before,
.nav-item .inner-nav::before {
  background: linear-gradient(
    60deg,
    rgb(152, 110, 208) 0%,
    rgb(173, 109, 191) 100%
  );
  left: 0;
  top: 0;
}

.nav-item2 .inner-nav2::after,
.nav-item .inner-nav::after {
  background: linear-gradient(
    60deg,
    rgb(209, 102, 161) 0%,
    rgb(248, 84, 123) 100%
  );
  right: 0;
  bottom: 0;
}

.header-small > .nav-item2:hover .title,
.header-small > .nav-item2.-active .title,
.header-small > .nav-item:hover .title,
.header-small > .nav-item.-active .title {
  color: #a458ac;
}

/*現在地とhoverした際の線の変化*/
.nav-item2.-active::before,
.nav-item2.-active::after,
.nav-item2:hover::before,
.nav-item2:hover::after,
.nav-item.-active::before,
.nav-item.-active::after,
.nav-item:hover::before,
.nav-item:hover::after {
  width: 100%;
}

.nav-item2.-active .inner-nav2::before,
.nav-item2.-active .inner-nav2::after,
.nav-item2:hover .inner-nav2::before,
.nav-item2:hover .inner-nav2::after,
.nav-item.-active .inner-nav::before,
.nav-item.-active .inner-nav::after,
.nav-item:hover .inner-nav::before,
.nav-item:hover .inner-nav::after {
  height: 100%;
}

.nav-mobile {
  display: none;

  @include m.nav() {
    display: block;
    position: absolute;
    top: 44px;
    right: calc(8% - 10px);
    transition: 0.5s ease-out;

    @include m.sp() {
      top: 18px;
    }
  }
}

.header-small {
  .nav-mobile {
    @include m.nav() {
      top: 18px;

      @include m.sp() {
        top: 3px;
        right: 8px;
      }
    }
  }
}

.nav-mobile-bg {
  display: none;

  @include m.nav() {
    display: block;
  }
}

@include m.nav() {
  .container-fluid {
    padding-top: 18px;
  }

  a.navbar-brand {
    display: block;
    height: 60px;
  }

  .navbar-brand img {
    height: 100%;
  }

  .nav {
    display: none;
  }

  .container-news {
    bottom: 140px;
    left: 10%;
    width: 90%;
    transform: translateX(0);
  }

  .nav-mobile-bg {
    display: block;
    position: fixed;
    width: 100%;
    height: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    right: 0;
    transition: all 0.6s;
    z-index: v.$zindex-background;
  }

  .nav-mobile-bg.-active {
    border-radius: 0;
    width: 100%;
    opacity: 1;
    height: 100vh;
    padding-top: 20vh;

    @include m.sp() {
      padding-top: 76px;
    }
  }

  .nav-mobile div {
    user-select: none;
    position: relative;
    color: #a458ac;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }

  .container-nav .nav-mobile {
    top: 23px;
  }

  .container-nav .nav-mobile div {
    color: #fff;
  }

  .nav-mobile span {
    display: inline-block;
    transition: all 0.4s;
    position: absolute;
    left: 8px;
    height: 3px;
    border-radius: 1px;
    background: #a458ac;
  }

  .container-nav .nav-mobile span {
    background: #fff;
  }

  .nav-mobile span:nth-of-type(1) {
    top: 8px;
    width: 70%;
  }

  .nav-mobile span:nth-of-type(2) {
    top: 20px;
    width: 70%;
  }

  .nav-mobile span:nth-of-type(3) {
    top: 32px;
    width: 70%;
  }

  .nav-mobile.-active span:nth-of-type(1) {
    top: 18px;
    left: 5px;
    transform: translateY(6px) rotate(-135deg);
    width: 80%;
  }

  .nav-mobile.-active span:nth-of-type(2) {
    opacity: 0;
  }

  .nav-mobile.-active span:nth-of-type(3) {
    top: 30px;
    left: 5px;
    transform: translateY(-6px) rotate(135deg);
    width: 80%;
  }

  #nav-mobile-bg .navbar-nav {
    display: none;
    text-align: center;
    list-style: none;
    padding: 0;
  }

  /* スクロール可動域制限*/
  #nav-mobile-bg.-active .navbar-nav {
    display: block;
    overflow: scroll;
    height: calc(100vh - 10vh);
    padding-bottom: 40vh;
  }

  #nav-mobile-bg .navbar-nav .nav-item {
    height: 44px;
    line-height: 44px;
  }

  #nav-mobile-bg .navbar-nav a.nav-link {
    display: block;
    color: v.$white;
    transform: skew(0);
    margin-left: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  #nav-mobile-bg .navbar-nav a.nav-link:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  #nav-mobile-bg .navbar-nav a.nav-link span {
    color: v.$white;
    transform: skew(0);
    transition: all 0.4s;
  }

  #nav-mobile-bg .navbar-nav a.nav-link:hover span {
    font-size: 1.1em;
  }
}

.accordion {
  a {
    display: block;
    text-decoration: none;
    color: v.$gray-1;
  }

  label {
    margin: 0;
    display: block;
    line-height: 1;
    color: v.$gray-1;
    cursor: pointer;
  }

  input {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    background: v.$white;
    list-style: none;
  }

  li {
    height: 0;
    transition: all 0.5s;
  }
}

.accordion-item:hover {
  .accordion-content {
    max-height: 200px;
    height: 44px;
  }
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  background-color: v.$gray-3;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  margin-top: 2px;
}

.accordion-content-link {
  color: v.$gray-1;

  display: flex;
  justify-content: center;
  align-items: center;
  //height: 100%;
  height: 44px;

  &:hover {
    color: v.$base-purple;
  }
}
</style>
